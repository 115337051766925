
import Vue from 'vue'
import { IMenuItem } from '../store/state'
import MenuItem from './MenuItem.vue'
import MenuItemMain from './MenuItemMain.vue'
//import MenuItem from ;

interface MenuMethods {
}
interface MenuComputed {
    menuItems(): IMenuItem[];
    activeFirstMenuItem(): IMenuItem;
    loginPageURL(): string;
    currentURL(): string;
    sitemapURI(): string;
}

interface MenuProps {
    menuItems: IMenuItem[]
   
}

const component: Function = Vue.extend<{}, MenuMethods, MenuComputed, MenuProps>({
    name: 'Menu',    
    computed: {
        menuItems() {
            return this.$store.getters.menuItems;
        },
        activeFirstMenuItem() {
            return this.$store.getters.activeFirstMenuItem;
        },
        loginPageURL() {
            return this.$store.state.loginPageURL;
        },
        currentURL() {
            return this.$store.state.currentURL;
        },
        sitemapURI() {
            return this.$store.state.sitemapURI;
        },
	},	
    components: {
		MenuItem,
        MenuItemMain
	},
})

export default component
