
import Vue from 'vue'
import { IMenuItem } from '../store/state';
import { toggleExpanded } from '../store/actions'
interface MenuItemMethods {
    toggleClick(): void
}

interface MenuItemComputed {
	activeFirstMenuItem: IMenuItem
    levelClass: string
}

interface MenuItemProps {
    item: IMenuItem
	
}

export default Vue.extend<{}, MenuItemMethods, MenuItemComputed, MenuItemProps>({
    name: 'MenuItem',
    props: {
		item: {},
	},
    methods: {
        toggleClick() {
            this.$store.dispatch(toggleExpanded(this.$props.item))
        }
    }, 
    computed: {
        levelClass() {
            return 'level-' + this.$props.item.level;
        },
        activeFirstMenuItem() {
            return this.$store.getters.activeFirstMenuItem;
        }
    },
	components: {
		
	}
})
