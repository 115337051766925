import { Getter } from "vuex";
import { IState } from './state';

const isStartPage: Getter<IState, IState> = (state) => state.isStartPage
const logotypeURI: Getter<IState, IState> = (state) => state.logotypeURI
const startPageURI: Getter<IState, IState> = (state) => state.startPageURI
const searchPageURI: Getter<IState, IState> = (state) => state.searchPageURI
const menuItems: Getter<IState, IState> = (state) => state.menuItems
const activeFirstMenuItem: Getter<IState, IState> = (state) => state.menuItems.find(mi => mi.isExpanded);
const currentURL:  Getter<IState, IState> = (state) => state.currentURL;
const sitemapURI:  Getter<IState, IState> = (state) => state.sitemapURI;

export default {
	isStartPage,
	logotypeURI,
	startPageURI,
	searchPageURI,
	menuItems,
	activeFirstMenuItem,
	currentURL,
	sitemapURI
}
