
import Vue from 'vue'

// Interfaces for component typechecking!
interface SearchData {
    query: string
}

interface SearchMethods {

}

interface SearchComputed {
	searchPageURI: string
}

interface SearchProps {

}

export default Vue.extend<SearchData, SearchMethods, SearchComputed, SearchProps>({
	name: 'Search',
	computed: {
		searchPageURI(){
			return this.$store.getters.searchPageURI;
		}
	},
	components: {
		
	}
})
