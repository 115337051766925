
import Vue from 'vue'
import Search from './components/Search.vue';
import Menu from './components/Menu.vue';

// Interfaces for component typechecking!
interface AppData {
	menuOpen: Boolean
}

interface AppMethods {
	toggleMenu(): void
}

interface AppComputed {
	isStartPage: boolean
	logotypeURI: string
	startPageURI: string
}

interface AppProps {

}

export default Vue.extend<AppData, AppMethods, AppComputed, AppProps>({
	name: 'app',
	data() {
		return {
			menuOpen: false
		}
	},
	computed: {
		isStartPage(){
			return this.$store.getters.isStartPage;
		},
		logotypeURI() {
			return this.$store.getters.logotypeURI;
		},
		startPageURI() {
			return this.$store.getters.startPageURI;
		}
	},
	methods: {
		toggleMenu() {
			this.menuOpen = !this.menuOpen;
			const html = document.documentElement;
			if(this.menuOpen) {				
				html.setAttribute('menu-open', '');
			} else {
				html.removeAttribute('menu-open');
			}
			
		}
	},
	components: {
		Search,
		Menu
	}
})
