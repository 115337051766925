import { IMenuItem, IState } from "./state";
import { ActionTree, Store, ActionContext } from 'vuex';
import * as api from '../api';

type PayloadType = 'initApp' | 'setState' | 'toggleExpanded' | 'getChildren'
export type ActionPayload<ActionData> = {
	type: PayloadType,
	payload: ActionData
}
export interface IGetChildrenPayload {
	id: string
	level: number
}
export interface IAddMenuItemsPayload {
	menuItem: IMenuItem,
	items: IMenuItem[]
}

type ActionHandler<ActionData> = (this: Store<IState>, injectee: ActionContext<IState, IState>, payload: ActionPayload<ActionData>) => any;
type ActionCreator<ActionData> = (data: ActionData) => ActionPayload<ActionData>;


// Action creators
export const initApp: ActionCreator<IState> = (state) => ({
	type: 'initApp',
	payload: state
})
export const toggleExpanded: ActionCreator<IMenuItem> = menuItem => ({
	type: 'toggleExpanded',
	payload: menuItem
})
export const getChildren: ActionCreator<IGetChildrenPayload> = payload => ({
	type: 'getChildren',
	payload: payload

})

// Action handlers
const getChildrenAction: ActionHandler<IMenuItem> = ({ commit, state }, { payload }) => {
	api.getMenuItems(state.endpoints.getChildren, {id: payload.id, level: payload.level})
		.then(res => {
			commit({
				type: 'addMenuItems',
				payload: {menuItem: payload, items: res}
			})
		})
}

const toggleExpandedAction: ActionHandler<IMenuItem> = ({ commit, dispatch, state }, { payload }) => {
	commit({
		type: 'toggleExpanded',
		payload: payload
	})
	if(payload.children.length < 1) {
		dispatch(getChildren(payload))
	}
}

const initAppAction: ActionHandler<IState> = ({ commit }, { payload }) => {
	commit({
		type: 'setState',
		payload
	})
}

const actions: ActionTree<IState, IState> = {
	initApp: initAppAction,
	toggleExpanded: toggleExpandedAction,
	getChildren: getChildrenAction,
}

export default actions;
